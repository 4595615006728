const Money = {
  methods: {
    formatMoney(number) {
      if (typeof number !== "undefined")
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
    },
    formatMoneyByCurrency(value, currency = 'BRL') {
      if (typeof value !== "undefined")
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
    },
  },
};

export default Money;
